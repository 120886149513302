import { useEffect } from 'react';

function Visualiser({ displayed }) {
    useEffect(() => {
        console.log("displaying " + displayed);
        const viewer = window.$3Dmol.viewers[0]
        if (viewer === undefined) {
            return
        }
        window.$3Dmol.download('pdb:'+displayed, viewer, {multimodel:true, frames:true},function(){
	        viewer.setStyle({}, {cartoon:{color:"spectrum"}});
            viewer.zoomTo();
	        viewer.render();
        })
    }, [displayed])
    return (
        <>
            <h1>{displayed}</h1>
            <div style={{height: "800px", width: "100%", position: "relative"}}
             className='viewer_3Dmoljs' 
             data-backgroundcolor='0xffffff' data-style='stick' data-ui='true'>
            </div>
        </>
    )
}


export default Visualiser;
