import { useState } from 'react';
import logo from './logo.svg';
import './App.css';
import Visualiser from './components/Visualiser.js'
import SearchBox from './components/SearchBox.js'
import ResultList from './components/ResultList.js'
import axios from 'axios'

const ENDPOINT_URL = "https://search.rcsb.org/rcsbsearch/v2/query"

function App() {
  const [searchResults, setSearchResults] = useState([]);
  const [displayed, setDisplayed] = useState(null);

  const searchClicked = async (search) => {
    const res = await axios.post(ENDPOINT_URL, 
      {
        "query": { 
            "type" : "terminal", 
            "service": "full_text", 
            "parameters": {
                "value": search
            }
        }, 
        "return_type": "entry"
    }, {
        "headers" : {
            "Content-Type": "application/json"
        }
    })

    const all_ids = res.data.result_set.map((x) => x.identifier)
    console.log(all_ids)
    setSearchResults(all_ids)
  }

  return (
    <div className="App">
        <nav className="navbar bg-body-tertiary">
          <div className="container-fluid">
            <a className="navbar-brand" href="#">RCSB Querier</a>
          </div>
        </nav>
        <div className="container-fluid mt-2">
          <div className="row">
            <div className="col-3">
              <div className="card bg-light">
                <div className="card-body">
                  <SearchBox onSearch={searchClicked} /> 
                  <ResultList elements={searchResults} onSelect={(id) => {setDisplayed(id)}}/>
                </div>
              </div>
            </div>
            <div className="col-9">
              <div className="card bg-light">
                <div className="card-body">
                    <Visualiser displayed={displayed}/>
                </div>
              </div>
            </div>
          </div>
        </div>
    </div>
  );
}

export default App;
