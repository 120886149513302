function ResultList({ elements, onSelect }) {
    const entries = elements.map((x) => {
            return (<li key={x} className="list-group-item" data-id={x} onClick={(event) => { onSelect(event.target.getAttribute("data-id")) } }>{x}</li>)
    })
    return (
        <ul className="list-group mt-3">{entries}</ul>
    )
}

export default ResultList;
